import Layout from "app/core/layouts/Layout"
import { orgPageGetServerSideProps } from "app/lib/orgPageGssp"
import { InferGetServerSidePropsType, GetServerSidePropsContext, getSession } from "blitz"
import OrgPage from "../../components/orgPage"

const OrgHome = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <OrgPage />
}

OrgHome.suppressFirstRenderFlicker = true
OrgHome.getLayout = (page) => <Layout>{page}</Layout>

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  return await orgPageGetServerSideProps(ctx)
}

export default OrgHome
